import { getImpressum } from '../utils/data'
import { useEffect, useState } from "react";


const API =
    process.env.REACT_APP_MODE === "development"
        ? process.env.REACT_APP_BACKEND_URL_DEV
        : process.env.REACT_APP_BACKEND_URL;

const Impressum = ({ impressum }) => {

    const [text, setText] = useState("");

    useEffect(() => {
        const _getImpressum = async () => {
            const data = await getImpressum();

            console.log(data);
            const { text } = data.data.attributes;
            setText(text);
        };
        _getImpressum();
    }, []);
    return (
        <>

            <div>
                <div className="bg-white min-h-full">
                    <div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
                        <div className="text-center">
                            <h2 className="text-base font-semibold text-yellow-600 tracking-wide uppercase pb-20">
                                <hr className="pb-4" />
                                iwebdev.dev & iwebdev.ch
                                <hr className="mt-4" />
                            </h2>
                            <div
                                className="prose  max-w-full text-black pt-10 pb-10 pl-5  pr-5 xl:pl-32 xl:pr-32 text-left"
                                dangerouslySetInnerHTML={{
                                    __html: text,
                                }}
                            ></div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
};

export default Impressum;



