import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import { Spinner, MasonryLayout } from "../components";
import { getPosts } from "../utils/data";

const Feed = () => {
  const [posts, setPosts] = useState();
  const [loading, setLoading] = useState(false);
  const { categoryId } = useParams();

  useEffect(() => {
    // console.log("Cat ID ", categoryId);
    const getAllPosts = async (categoryId) => {
      const res = await getPosts(categoryId);
      // console.log("RES", res);
      setPosts(res);
    };

    setLoading(true);
    getAllPosts(categoryId);
    setLoading(false);
  }, [categoryId]);

  const ideaName = categoryId || "new";
  if (loading) {
    return (
      <Spinner message={`We are adding ${ideaName} posts to your feed!`} />
    );
  }
  return (
    <div>
      {posts && <MasonryLayout pins={posts} />}
      {/* <span>{posts && console.log("Posts", posts)}</span> */}
    </div>
  );
};

export default Feed;
