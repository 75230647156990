import React from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import Home from "./container/Home";
import { ToastContainer, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import axios from "axios";
import jwt_decode from "jwt-decode";
import { getAccessToken, storeJWTToken } from "./utils/helpers";
import { Helmet } from 'react-helmet';
import Fathom from 'fathom-react';


import { Login } from "./components";

export const axiosJWT = axios.create()

const App = () => {



  const getRefreshToken = async () => {
    try {
      //   console.log("getRefreshToken");

      let API = process.env.REACT_APP_MODE === 'development' ? process.env.REACT_APP_BACKEND_URL_DEV : process.env.REACT_APP_BACKEND_URL

      const res = await axios.post(`${API}/token/refresh`, { withCredentials: true });

      return res.data;
    } catch (err) {
      console.log(err);
    }
  };

  axiosJWT.interceptors.request.use(
    async (config) => {
      const accessToken = getAccessToken()
      if (accessToken) {
        //console.log("Interceptor - accesstoken", accessToken)
        let currentDate = new Date();
        const decodedToken = jwt_decode(accessToken);
        console.log(decodedToken.exp * 1000, currentDate.getTime())
        if (decodedToken.exp * 1000 < currentDate.getTime()) {
          console.log("get a new accesstoken")
          const data = await getRefreshToken();
          if (data) {
            storeJWTToken(data.jwt)
            config.headers["authorization"] = "Bearer " + data.jwt;
          } else {
            // no auth header
          }
        } else {
          console.log("no new accesstoken required")
          config.headers["authorization"] = "Bearer " + accessToken;
        }
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  return (
    <>
      <Helmet>
        <title>My Blog</title>
        <meta name="description" content="iWebDev.dev MyBlog Application" />
      </Helmet>
      <Fathom siteId="PBJBGGZJ">
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/*" element={<Home />} />

        </Routes>
      </Fathom>
      <ToastContainer position="bottom-center" autoClose={2000} transition={Slide} />
    </>
  );
};

export default App;
