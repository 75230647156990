import React, { useState } from "react";
import { Link, Route, Routes } from "react-router-dom";
import Feed from "./Feed";
import { Navbar } from "../components";
import PostDetail from "./PostDetail";
import Search from "./Search";

const Posts = () => {
  const [searchTerm, setSearchTerm] = useState("");

  return (
    <div className="px-2 md:px-5">
      <div className="bg-gray-50">
        <Navbar searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
      </div>
      <div className="h-full">
        <Routes>
          <Route path="/" exact element={<Feed />} />

          <Route path="/category/:categoryId" element={<Feed />} />
          <Route path="/post-detail/:postId" element={<PostDetail />} />
          <Route
            path="/search"
            element={
              <Search searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
            }
          />
        </Routes>
      </div>
    </div>
  );
};

export default Posts;
