import { useForm } from "react-hook-form";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

import {
  FormErrorMessage,
  FormLabel,
  FormControl,
  Input,
  Button,
  Box,
  Center,
  Text,
  Textarea,
} from "@chakra-ui/react";

const API =
  process.env.REACT_APP_MODE === "development"
    ? process.env.REACT_APP_BACKEND_URL_DEV
    : process.env.REACT_APP_BACKEND_URL;

export default function ContactForm() {
  const [captcha, setCaptcha] = useState({});
  const [isWrong, setIsWrong] = useState(false);
  const navigate = useNavigate();

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm();

  const onSubmit = async (values, e) => {
    try {
      console.log("Captcha from State", captcha);
      const content = await axios(`${API}/captcha/solve`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        data: {
          data: {
            token: captcha.token,
            firstname: values.firstname,
            lastname: values.lastname,
            email: values.email,
            result: values.result,
            text: values.text,
          },
        },
      });
      console.log(content);
      toast.success("Contact data sent!");
      navigate("/");
    } catch (error) {
      console.log(error);
      setIsWrong(!isWrong);
      toast.error(error.response.data.message);
    }
  };

  useEffect(() => {
    const getCaptcha = async () => {
      console.log("get Captcha");
      try {
        const content = await axios(`${API}/captcha`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        });
        setCaptcha(content.data);
        console.log(content.data);
      } catch (error) {
        console.log(error);
      }
    };

    getCaptcha();
  }, [isWrong]);

  return (
    <>
      {captcha ? (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Center>
            <Box
              p={4}
              color="black"
              bg="white"
              mt={10}
              borderRadius="lg"
              w="80%"
              display="flex-col"
              alignItems="center"
              maxW="md"
            >
              <Center>
                <Text fontSize={22}>Contact form</Text>
              </Center>

              <FormControl isInvalid={errors.firstname}>
                <FormLabel htmlFor="firstname">First name</FormLabel>
                <Input
                  id="firstname"
                  placeholder="First name"
                  {...register("firstname", {
                    required: "Firstname is required",
                    minLength: {
                      value: 4,
                      message: "Minimum length should be 4",
                    },
                  })}
                />
                <FormErrorMessage>
                  {errors.firstname && errors.firstname.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={errors.lastname}>
                <FormLabel htmlFor="lastname">Last name</FormLabel>
                <Input
                  id="lastname"
                  placeholder="Last name"
                  {...register("lastname", {
                    required: "Lastname is required",
                    minLength: {
                      value: 4,
                      message: "Minimum length should be 4",
                    },
                  })}
                />
                <FormErrorMessage>
                  {errors.lastname && errors.lastname.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={errors.email}>
                <FormLabel htmlFor="email">EMail</FormLabel>
                <Input
                  type="email"
                  id="email"
                  placeholder="Email"
                  {...register("email", {
                    required: "Email is required",
                  })}
                />
                <FormErrorMessage>
                  {errors.emails && errors.email.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={errors.text}>
                <FormLabel htmlFor="text">Text</FormLabel>
                <Textarea
                  id="text"
                  {...register("text", {
                    required: "Text is required",
                    minLength: {
                      value: 20,
                      message: "Minimum length should be 20",
                    },
                  })}
                />
                <FormErrorMessage>
                  {errors.text && errors.text.message}
                </FormErrorMessage>
              </FormControl>

              <div
                className="pt-4 pb-5"
                dangerouslySetInnerHTML={{
                  __html: captcha.svg,
                }}
              ></div>

              <FormControl isInvalid={errors.result}>
                <FormLabel htmlFor="result">Text from picture</FormLabel>
                <Input
                  id="result"
                  placeholder="Result from picture"
                  {...register("result", {
                    required: "Result is required",
                    minLength: {
                      value: 6,
                      message: "Minimum length should be 6",
                    },
                  })}
                />
                <FormErrorMessage>
                  {errors.result && errors.result.message}
                </FormErrorMessage>
              </FormControl>
              <Button
                mt={4}
                colorScheme="teal"
                isLoading={isSubmitting}
                type="submit"
              >
                Submit
              </Button>
            </Box>
          </Center>
        </form>
      ) : (
        <span>No Token</span>
      )}
    </>
  );
}
