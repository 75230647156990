import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getAccessToken, isSignedIn, unsSetUser } from "../utils/helpers";
import axios from "axios";

const API =
  process.env.REACT_APP_MODE === "development"
    ? process.env.REACT_APP_BACKEND_URL_DEV
    : process.env.REACT_APP_BACKEND_URL;

const Logout = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const signeOut = async () => {
      try {
        axios.defaults.withCredentials = true;
        const response = await axios(`${API}/auth/logout`, {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            authorization: "Bearer " + getAccessToken(),
            // withCredentials: true,
          },
        });
      } catch (error) {
        console.log(error);
      }
    };

    if (isSignedIn) {
      signeOut();
      unsSetUser();
      navigate("/");
    }
  }, []);

  return <div></div>;
};

export default Logout;
