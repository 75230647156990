import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { IoMdAdd, IoMdSearch } from "react-icons/io";
import { isSignedIn } from "../utils/helpers";

const Navbar = ({ searchTerm, setSearchTerm }) => {
  const navigate = useNavigate();

  return (
    <div className=" bg-gray-200 flex gap-2 md:gap-5 w-full mt-5 pb-2 ">
      <div className="bg-gray-200 flex justify-start items-center w-2/3 px-2 rounded-md border-none outline-none focus-within:shadow-sm">
        <IoMdSearch fontSize={21} className="ml-1" />
        <input
          type="text"
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder="Search"
          value={searchTerm}
          onFocus={() => navigate("/search")}
          className="p-2 w-full bg-white outline-none"
        />
      </div>
      {!isSignedIn() && (
        <Link to="/login1" className="flex px-5 gap-2  pt-1 w-190 items-center">
          Login
        </Link>
      )}
      {isSignedIn() && (
        <>
          <Link
            to="/logout"
            className="flex px-5 gap-2  pt-1 w-190 items-center"
          >
            Logout
          </Link>
        </>
      )}
    </div>
  );
};

export default Navbar;
