import React, { useState } from "react";

import { Link, useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { BsFillArrowUpRightCircleFill } from "react-icons/bs";
import { FaRegComments } from "react-icons/fa";
import { urlMaker } from "../utils/helpers";
import Moment from "react-moment";

const Post = ({ post }) => {
  const [postHovered, setPostHovered] = useState(false);

  const navigate = useNavigate();

  if (!post.publishedAt) return null;

  return (
    <div className="m-2 bg-sky-900 p-1 shadow-lg">
      <div
        onMouseEnter={() => setPostHovered(true)}
        onMouseLeave={() => setPostHovered(false)}
        onClick={() => navigate(`/post-detail/${post.id}`)}
        className=" relative cursor-zoom-in w-auto hover:shadow-lg rounded-lg overflow-hidden transition-all duration-500 ease-in-out "
      >
        {post.headerimage && (
          <img
            className="rounded-lg w-full "
            src={urlMaker(post.headerimage.url)}
            alt="user-post"
          />
        )}
        <div
          className="absolute top-0 w-full h-full flex flex-col justify-between p-1 pr-2 pt-2 pb-2 z-50"
          style={{ height: "100%" }}
        >
          <span className=" bg-gray-300  text-black p-1 flex flex-col text-center opacity-70 round-lg shadow-lg">
            {post.title}
          </span>
        </div>
        {postHovered && (
          <div
            className="absolute top-0 w-full h-full flex flex-col justify-between p-1 pr-2 pt-2 pb-2 z-50"
            style={{ height: "100%" }}
          >
            <div className="flex items-center justify-between"></div>
            <div className=" flex justify-between items-center gap-2 w-full text-white">
              <BsFillArrowUpRightCircleFill />
            </div>
          </div>
        )}
      </div>
      <div className="w-full p-2 ">
        <div className="flex">
          <div className="flex-1 text-left text-white text-sm">
            {post.subtitle}
          </div>
          <div className=" flex-2 text-left  content-end  gap-5 text-white text-xs">
            {post.comments.length > 0 && (
              <>
                <FaRegComments /> {post.comments.length}
              </>
            )}
          </div>
        </div>

        <div className="text-xs text-slate-400 ">
          <Moment format="DD.MM.yyyy">{post.createdAt}</Moment>
        </div>
        {post.users_permissions_user && (
          <div className="flex flex-row place-content-end">
            <div className="flex gap-5 text-gray-500 text-xs pr-5">
              created By
              <div className="text-right text-slate-400 text-xs">
                {post.users_permissions_user.username}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Post;
