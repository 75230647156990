import jwt_decode from 'jwt-decode'

const API =
    process.env.REACT_APP_MODE === "development"
        ? process.env.REACT_APP_BACKEND_URL_DEV
        : process.env.REACT_APP_BACKEND_URL;

const BACKEND = process.env.REACT_APP_MODE === "development"
    ? process.env.REACT_APP_BACKEND_DEV
    : process.env.REACT_APP_BACKEND;

export const urlMaker = (url) => {

    let _url = BACKEND + url

    return _url

}

export const getAccessToken = () => {
    const accessToken = localStorage.getItem("jwt")
    // console.log("----- ", accessToken)
    return accessToken
}

export const storeJWTToken = (token) => {
    if (token) {
        localStorage.setItem("jwt", token)
    }
}

export const setUser = (user) => {
    console.log(user)
    if (user.jwt) {
        storeJWTToken(user.jwt)
    }

    localStorage.setItem('user.id', user.user.id)
    localStorage.setItem('user.firstname', user.user.firstname)
}

export const unsSetUser = () => {

    localStorage.clear()
}

export const isSignedIn = () => {

    if (localStorage.getItem('jwt') && localStorage.getItem('user.id')) {
        const decodedToken = jwt_decode(localStorage.getItem('jwt'))
        //  console.log(decodedToken.id, localStorage.getItem('user.id'))
        if (decodedToken.id === parseInt(localStorage.getItem("user.id"))) {
            return true
        } else
            return false
    }

    else
        return false
}