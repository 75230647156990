import React, { useEffect, useState } from "react";
import { NavLink, Link } from "react-router-dom";
import { RiHomeFill } from "react-icons/ri";
import { IoIosArrowForward } from "react-icons/io";
import { MdOutlineContactMail } from "react-icons/md";
import { SiAboutdotme } from "react-icons/si";
import logo from "../assets/logowhite.png";
import { getCategories } from "../utils/data";
import axios from "axios";
import Category from "./Category";

const isNotActiveStyle =
  "flex items-center px-5 gap-3 text-gray-200 hover:text-blue transition-all duration-200 ease-in-out capitalize";
const isActiveStyle =
  "flex items-center px-5 gap-3 font-extrabold border-r-2 border-black  transition-all duration-200 ease-in-out capitalize";

const Sidebar = ({ closeToggle }) => {
  const [categories, setCategories] = useState(null);

  const handleCloseSidebar = () => {
    if (closeToggle) closeToggle(false);
  };

  useEffect(() => {
    const getCat = async () => {
      const res = await getCategories();
      setCategories(res);
    };
    getCat();
  }, []);

  return (
    <div className="flex flex-col justify-between bg-slate-900  h-full overflow-y-scroll min-w-210 hide-scrollbar">
      <div className="flex flex-col">
        <Link
          to="/"
          className="flex px-5 gap-2 my-6 pt-1 w-190 items-center"
          onClick={handleCloseSidebar}
        >
          <img src={logo} alt="logo" className="w-full" />
        </Link>
        <div className="flex text-white flex-col gap-5">
          <NavLink
            to="/"
            className={({ isActive }) =>
              isActive ? isActiveStyle : isNotActiveStyle
            }
            onClick={handleCloseSidebar}
          >
            <RiHomeFill />
            Home
          </NavLink>

          <NavLink
            to="/about"
            className={({ isActive }) =>
              isActive ? isActiveStyle : isNotActiveStyle
            }
            onClick={handleCloseSidebar}
          >
            <SiAboutdotme />
            About me
          </NavLink>

          <NavLink
            to="/contact"
            className={({ isActive }) =>
              isActive ? isActiveStyle : isNotActiveStyle
            }
            onClick={handleCloseSidebar}
          >
            <MdOutlineContactMail />
            Contact me
          </NavLink>

          <h3 className="mt-2 px-5 text-base 2xl:text-xl">Categories</h3>

          {categories &&
            categories?.map((c, i) => (
              <Category
                key={i}
                category={c}
                handleCloseSidebar={handleCloseSidebar}
              />
            ))}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
