import React, { useState } from "react";
import { toast } from "react-toastify";
import { Formik, Form, ErrorMessage } from "formik";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import axios from "axios";
import { setUser } from "../utils/helpers";
import { TextField } from "./formcomponents/TextField";
import Loader from "react-loader-spinner";

const API =
  process.env.REACT_APP_MODE === "development"
    ? process.env.REACT_APP_BACKEND_URL_DEV
    : process.env.REACT_APP_BACKEND_URL;

const Login = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const LoadingSpinner = () => {
    return (
      <Loader
        type="Bars"
        color="#00BFFF"
        height={200}
        width={200}
        timeout={3000} //3 secs
      />
    );
  };

  const validate = Yup.object({
    identifier: Yup.string()
      .email("EMail is invalid")
      .required("EMail is required"),
    password: Yup.string().required("Password is required"),
  });

  const signInForm = () => {
    return (
      <Formik
        initialValues={{
          identifier: "",
          password: "",
        }}
        validationSchema={validate}
        const
        onSubmit={async (values) => {
          console.log("Values", JSON.stringify(values));
          setLoading(true);
          try {
            axios.defaults.withCredentials = true;
            const response = await axios(`${API}/auth/local`, {
              method: "POST",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                crossDomain: true,

                withCredentials: true,
              },
              data: JSON.stringify(values),
            });
            //console.log("response ", response)
            setLoading(false);
            if (response.status === 200) {
              //          console.log(response.data);

              setUser(response.data);
              toast.success("Signin successfully!");
              navigate("/");
            } else {
              console.log("Error ", response.status);
            }
          } catch (err) {
            setLoading(false);
            console.log("Error -> ", err.response.data.error.message);
            if (err.response.status === 401) {
              if (err.response.data.err) {
                toast.error(err.response.data.err);
              } else {
                toast.error(err.response.data.error);
              }
            } else {
              //console.log(err.response.data)
              toast.error(err.response.data.error.message);
            }
          }
        }}
      >
        {(formik) => (
          <div className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
            <div className="max-w-md w-full space-y-8">
              {loading ? (
                <LoadingSpinner />
              ) : (
                <div className="mt-8 space-y-6">
                  <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
                    <div className="sm:mx-auto sm:w-full sm:max-w-md">
                      <h2 className="mt-6 text-center text-3xl font-extrabold text-white">
                        Sign in to your account
                      </h2>
                    </div>

                    <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                      <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                        <Form className="space-y-6">
                          <TextField
                            type="email"
                            label="EMail"
                            name="identifier"
                            placeholder="Your EMail"
                          />
                          <TextField
                            type="password"
                            label="Password"
                            name="password"
                            placeholder="Your password"
                          />

                          <div className="flex items-center justify-between">
                            <div className="text-sm">
                              <a
                                href="#"
                                className="font-medium text-indigo-600 hover:text-indigo-500"
                              >
                                Forgot your password?
                              </a>
                            </div>
                          </div>

                          <div>
                            <button
                              type="submit"
                              className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            >
                              Sign in
                            </button>
                          </div>
                        </Form>

                        <div className="mt-6">
                          <div className="relative">
                            <div className="absolute inset-0 flex items-center">
                              <div className="w-full border-t border-gray-300" />
                            </div>
                            <div className="relative flex justify-center text-sm">
                              <span className="px-2 bg-white text-gray-500">
                                Or SignUp
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </Formik>
    );
  };

  return signInForm();
};

export default Login;
