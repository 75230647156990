import React from "react";
import { NavLink, Link } from "react-router-dom";

const AboutMe = () => {
  return (
    <div className="bg-white min-h-full">
      <div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
        <div className="text-center">
          <h2 className="text-base font-semibold text-yellow-600 tracking-wide uppercase pb-20">
            <hr className="pb-4" />
            Meet iwebdev.dev
            <hr className="mt-4" />
          </h2>
          <p></p>
          <div className="flex">
            <div className="flex-none "></div>
            <div className="flex-auto w-64 font-sans">
              <p className=" text-slate-700 text-md p-2 text-left">
                iwebdev.dev is developing smaller web applications using React,
                node.js, mySQL, Strapi...
              </p>

              <p></p>
              <p className="text-left p-2 text-slate-600">
                In this blog I will present a few works, but also something
                about my hobby photography.
              </p>
              <p className="text-left p-2 text-slate-600">
                I have been doing photography - like software devlopment - for
                almost 40 years, during this time there has been an enormous
                development on both sides.
              </p>
              <p className="pt-4 text-slate-600 text-left">
                What else do I like to do...
              </p>
              <ul>
                <li className=" list-disc ml-12 text-left">Hiking</li>
                <li className=" list-disc ml-12 text-left">Cooking</li>
                <li className=" list-disc ml-12 text-left">Reading Books</li>
              </ul>
              <p className=" text-slate-600 pt-10 text-left">
                I hope this has helped a little for contacts please use the
                contact form
              </p>
              <p className=" text-slate-900 text-xs pt-20 text-left">
                {Date()}
              </p>
              <div className="">
                <h2 className="mt-2">Weitere Infos</h2>
                <h2 className="mt-2 text-left gap-5 flex">
                  <NavLink to="/datenschutz">Datenschutz</NavLink>
                  <NavLink to="/impressum">Impressum</NavLink>
                </h2>
              </div>
            </div>

            <div className="flex-auto w-40 flex-row">
              <img
                className="rounded-lg shadow-lg max-w-full max-h-screen"
                style={{ width: "auto", maxHeight: "80%" }}
                src="https://images.pexels.com/photos/1967535/pexels-photo-1967535.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500"
                alt="about"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutMe;
