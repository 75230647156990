import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { Formik, Form, ErrorMessage } from "formik";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import axios from "axios";
import { axiosJWT } from "../App";
import { getAccessToken, isSignedIn } from "../utils/helpers";
import Loader from "react-loader-spinner";
import { TextAreaField } from "./formcomponents/TextAreaField";
import { TextField } from "./formcomponents/TextField";

const API = process.env.REACT_APP_BACKEND_URL;

const NewComment = ({ postId }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const validate = Yup.object({
    comment: Yup.string().required("Comment is required"),
  });

  const LoadingSpinner = () => {
    return (
      <Loader
        type="Bars"
        color="#00BFFF"
        height={200}
        width={200}
        timeout={3000} //3 secs
      />
    );
  };

  if (!isSignedIn()) return null;

  const addCommentForm = () => {
    return (
      <Formik
        initialValues={{
          comment: "",
          post: postId,
        }}
        validationSchema={validate}
        const
        onSubmit={async (values) => {
          setLoading(true);
          try {
            axios.defaults.withCredentials = true;
            const post = { post: postId };
            values = { ...values, ...post };
            //  console.log(JSON.stringify(values));
            const response = await axiosJWT(`${API}/comments`, {
              method: "POST",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                authorization: "Bearer " + getAccessToken(),
                withCredentials: true,
              },
              data: {
                data: {
                  comment: values.comment,
                  post: values.post,
                },
              },
            });
            //console.log("response ", response)
            setLoading(false);
            if (response.status === 200) {
              // console.log(response.data);
              if (!response.data.enabledState) {
                toast.success("Comment saved, but must first approved");
              } else toast.success("Comment saved");
              navigate("/");
            } else {
              console.log("Error ", response);
            }
          } catch (err) {
            setLoading(false);
            //console.log("Error -> ", err.response.status);
            if (err.response.status === 401) {
              if (err.response.data.message) {
                toast.error(err.response.data.message);
              } else {
                toast.error(err.response.data.message);
              }
            } else {
              //console.log(err.response.data)
              toast.error(err.response.data.message);
            }
          }
        }}
      >
        {(formik) => (
          <div className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
            <div className="max-w-md w-full space-y-8">
              {loading ? (
                <LoadingSpinner />
              ) : (
                <div className="mt-8 space-y-6">
                  <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
                    <div className="sm:mx-auto sm:w-full sm:max-w-md">
                      <h2 className="mt-6 text-center text-3xl font-extrabold text-white">
                        Add a comment
                      </h2>
                    </div>

                    <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                      <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                        <Form className="space-y-6">
                          <input
                            type="text"
                            value={postId}
                            name="post"
                            readOnly
                            hidden
                          />
                          <TextAreaField
                            type="text"
                            label="Comment"
                            name="comment"
                            placeholder="Your Comment"
                          />

                          <div>
                            <button
                              type="submit"
                              className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            >
                              Send Comment
                            </button>
                          </div>
                        </Form>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </Formik>
    );
  };
  return addCommentForm();
};

export default NewComment;
