import axios from "axios"
import { axiosJWT } from "../App";
import { getAccessToken } from "./helpers";
import Cookies from "js-cookie";

const API =
    process.env.REACT_APP_MODE === "development"
        ? process.env.REACT_APP_BACKEND_URL_DEV
        : process.env.REACT_APP_BACKEND_URL;

const BACKEND = process.env.REACT_APP_MODE === "development"
    ? process.env.REACT_APP_BACKEND_DEV
    : process.env.REACT_APP_BACKEND;


export const getCategories = async () => {

    let retData = []
    let locale = 'en'

    try {
        const data = await axios.get(
            API + "/categories?locale=" + locale
        );
        let r = data?.data;
        r.data.forEach((element) => {
            retData.push(element);
        });

    } catch (error) {
        console.log(error)
    }


    return retData
}

/**
 * 
 * Get the post from Strapi
 * 
 * @param {*} category 
 * @returns 
 */
export const getPosts = async (category) => {

    console.log(API);

    let retData = []
    let locale = 'en'
    try {

        let additional = ""

        if (category) {
            // additional += `?filters[categories][id][$eq]=${category}`
            additional += "?categories=" + category

        } else {
            additional = ""
        }

        const data = await axios.get(
            //process.env.REACT_APP_BACKEND_URL + "/posts?locale=" + locale + "&populate=*" + additional
            API + "/posts/findall" + additional)

        let r = data?.data;
        //console.log("r", r)
        retData = r
        /*        await r.data.forEach((element) => {
                   retData.push(element);
               });
        */
    } catch (error) {
    }


    return retData
}


/**
 * Anzahl Post für die Kategorie
 */
export const getCountOfCat = async (cat) => {
    //console.log("Cate", cat.attributes.category)
    let retData = []
    try {
        const data = await axios.get(
            API + "/posts/count?category=" + cat.attributes.category
        );
        retData = data.data.count
        //console.log("retData ", retData)

    } catch (error) {
    }
    return retData
}

/**
 * 
 * @param {*} filter 
 * @returns 
 */
export const getPostsFiltered1 = async (filter) => {
    const qs = require('qs');

    const query = qs.stringify({
        filters: {
            $or: [
                {
                    title: {
                        $contains: `${filter}`,
                    },
                },
                {
                    subtitle: {
                        $contains: `${filter}`,
                    },
                },
            ],
        },
    }, {
        encodeValuesOnly: true,
    });

    let retData = []
    let locale = 'en'
    try {


        const data = await axios.get(
            API + "/posts?locale=" + locale + "&populate=*&" + query
        );
        let r = data?.data;
        await r.data.forEach((element) => {
            retData.push(element);
        });

    } catch (error) {
    }


    return retData
}


/**
 * 
 * @param {*} filter 
 * @returns 
 */
export const getPostsFiltered = async (filter) => {

    let retData = []
    try {

        let additional = ""

        if (filter) {
            // additional += `?filters[categories][id][$eq]=${category}`
            additional += "?filter=" + filter

        } else {
            additional = "?filter="
        }

        const data = await axios.get(
            //process.env.REACT_APP_BACKEND_URL + "/posts?locale=" + locale + "&populate=*" + additional
            API + "/posts/findall" + additional)

        let r = data?.data;
        console.log("r", r)
        retData = r
        /*        await r.data.forEach((element) => {
                   retData.push(element);
               });
        */
    } catch (error) {
    }


    return retData
}

export const setalreadyVoted = (postId) => {
    //  console.log("set voted", postId)
    let alreadyVoted = false
    if (postId) {
        let votingValues = Cookies.get(process.env.REACT_APP_VOTE_COOKIENAME)
        if (votingValues) {
            let v = votingValues
            let s = v.split("@")

            console.log(v, s)
            if (v.includes(`#${postId}@`)) {
                alreadyVoted = true
            } else {
                v += `#${postId}@,`

                Cookies.set(
                    process.env.REACT_APP_VOTE_COOKIENAME,
                    v,
                    { expires: 365 }
                )
            }

        } else {
            //        console.log("setCookie")
            Cookies.set(
                process.env.REACT_APP_VOTE_COOKIENAME,
                `#${postId}@,`,
                { expires: 365 }
            )
        }
    }
    return alreadyVoted
}

// checkt ob die Post von dem User schon gevoted wurde
//
export const checkIfalreadyVoted = (postId) => {
    // check if already voted
    //   console.log("check voted", postId)
    let alreadyVoted = false
    if (postId) {
        let votingValues = Cookies.get(process.env.REACT_APP_VOTE_COOKIENAME)
        if (votingValues) {
            let v = votingValues
            let s = v.split("@")

            //      console.log(v, s)
            if (v.includes(`#${postId}@`)) {
                alreadyVoted = true
            }

        }
    }
    return alreadyVoted
}

/**
 * 
 *   Set Votes
 * 
 * @param {*} wie 
 * @param {*} postId 
 */
export const setVote = async (wie, postId) => {

    if (!checkIfalreadyVoted(postId)) {
        setalreadyVoted(postId)
    }
    try {
        const votes = await axios(
            API + `/posts/vote`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                //   authorization: "Bearer " + getAccessToken(),
                //  withCredentials: true,
            },
            data: {
                "wie": wie,
                "postId": postId
            }
        }
        );
        return votes
    } catch (error) {
        return error
    }
}

export const getPost = async (id) => {



    let retData
    let locale = 'en'
    try {

        console.log("getSingle", id)

        axios.defaults.withCredentials = true;

        const data = await axios(
            API + `/posts/${id}`, {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                //   authorization: "Bearer " + getAccessToken(),
                //  withCredentials: true,
            },
        }
        );
        //console.log("DATA ", data.data)
        let r = data?.data;
        // bei eingebetten Bildern muss die URL geändert werden
        r.content = r.content.replaceAll("/uploads", BACKEND + '/uploads')
        retData = r
        //console.log("retData single", retData)

    } catch (error) {
        console.log(error)
    }

    return retData
}



export const getDatenSchutz = async () => {
    let retData

    try {
        axios.defaults.withCredentials = true;

        const data = await axios(
            API + `/datenschutz`, {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                //   authorization: "Bearer " + getAccessToken(),
                //  withCredentials: true,
            },
        }
        );
        console.log(data);

        if (data) {
            retData = data.data
        } else {
            retData = { "message": "not found" }
        }

    } catch (error) {
        console.log(error);
    }
    return retData
}


export const getImpressum = async () => {
    let retData

    try {
        axios.defaults.withCredentials = true;

        const data = await axios(
            API + `/impressum`, {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                //   authorization: "Bearer " + getAccessToken(),
                //  withCredentials: true,
            },
        }
        );
        console.log("DATA ", data.data);

        if (data) {
            let r = data?.data;
            //console.log("R", r.data);
            // bei eingebetten Bildern muss die URL geändert werden
            r.data.attributes.text = r.data.attributes.text.replace("/uploads", BACKEND + '/uploads')
            retData = r
            //   retData = data.data
        } else {
            retData = {
                data: { "text": "error" }
            }
        }

    } catch (error) {
        retData = {
            data: { "text": "error" }
        }
    }
    console.log("RETDATA", retData);
    return retData
}

