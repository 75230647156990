import React, { useEffect, useState } from "react";
import { BsHandThumbsDown, BsHandThumbsUp } from "react-icons/bs";
import { Link, useParams } from "react-router-dom";
import { checkIfalreadyVoted, getPost, setVote } from "../utils/data";
import { urlMaker } from "../utils/helpers";
import { Zoom } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Moment from "react-moment";
import Spinner from "./Spinner";
import Comment from "./Comment";
import { NewComment } from ".";
import { Box, Flex, Grid, Spacer, Text } from "@chakra-ui/react";

const PostDetail = () => {
  const { postId } = useParams();
  const [postDetail, setPostDetail] = useState();
  const [votes, setVotes] = useState({});
  const navigate = useNavigate();

  const vote = async (wie) => {
    if (!checkIfalreadyVoted(postId)) {
      try {
        const vote = await setVote(wie, postId);
        // console.log("vote", vote);
        if (vote.status === 200) {
          setVotes(vote.data);
        }
      } catch (error) {
        toast.error(error);
      }
    } else {
      toast.error("You have already voted this post");
    }
  };

  useEffect(() => {
    console.log("ID ", postId);
    const getPostDetail = async (postId) => {
      const data = await getPost(postId);
      if (data) {
        setPostDetail(data);
        setVotes(data.votes);
      } else {
        console.log("Not authorized");
        toast.error("Please login first");
        navigate("/");
      }
    };

    getPostDetail(postId);
  }, [postId]);

  const zoomOutProperties = {
    duration: 5000,
    transitionDuration: 500,
    infinite: true,
    indicators: true,
    scale: 0.4,
    arrows: true,
  };

  return (
    <>
      {postDetail && (
        <>
          <div className=" bg-sky-900 min-h-full">
            <div className="flex xl:flex-row flex-col m-auto  p-5 xl:w-3/5  gap-2 justify-center flex-end bg-sky-900">
              <img src={urlMaker(postDetail.headerimage.url)} alt="user-post" />
            </div>
          </div>
          <div className="w-full p-5 bg-sky-900 xl:min-w-620">
            <div>
              <h1 className="text-4xl text-zinc-300 font-bold text-center break-words mt-2">
                {postDetail.title}
              </h1>
              <p className="mt-2 text-center text-zinc-400">
                {postDetail.subtitle}
              </p>
            </div>
            <div>
              <Flex>
                {postDetail.canvote && (
                  <Box>
                    <Text textColor="white" className="ml-5 pb-2">
                      Voting
                    </Text>
                    <Flex>
                      <Box w="70px" textColor="white">
                        {!checkIfalreadyVoted(postId) && (
                          <BsHandThumbsUp
                            onClick={() => vote("up")}
                            className=" cursor-pointer text-center text-green-400"
                          />
                        )}
                        {checkIfalreadyVoted(postId) && (
                          <BsHandThumbsUp className="text-center text-green-400" />
                        )}
                      </Box>
                      <Box w="70px">
                        {!checkIfalreadyVoted(postId) && (
                          <BsHandThumbsDown
                            onClick={() => vote("down")}
                            className=" cursor-pointer text-center text-red-400"
                          />
                        )}
                        {checkIfalreadyVoted(postId) && (
                          <BsHandThumbsDown className="  text-center text-red-400" />
                        )}
                      </Box>
                    </Flex>
                    {votes && (
                      <Flex>
                        <Box w="70px">
                          <Text className=" text-left text-green-500  mt-1">
                            {votes.upvotes}
                          </Text>
                        </Box>
                        <Box w="70px">
                          <Text className=" text-left text-red-500  mt-1">
                            {votes.downvotes}
                          </Text>
                        </Box>
                      </Flex>
                    )}
                  </Box>
                )}
                <Spacer />
                <Grid templateColumns="repeat(1, 1fr)" gap={6}>
                  <Box>
                    <Text className="mt-2 text-md  pr-20 text-zinc-400">
                      Categories
                    </Text>
                  </Box>
                  <Box>
                    <ul className="flex flex-row place-content-end text-right">
                      {postDetail.categories.map((cat, i) => (
                        <li
                          key={i}
                          className=" text-xs bg-orange-100 p-1 rounded-full"
                        >
                          {cat.category}
                        </li>
                      ))}
                    </ul>
                  </Box>
                </Grid>
              </Flex>
            </div>
          </div>
          <div className="w-full  bg-zinc-200 p-4">
            <div
              className="prose  max-w-full bg-gray-200 "
              dangerouslySetInnerHTML={{
                __html: postDetail.content,
              }}
            ></div>
          </div>

          {postDetail.sliderimages && (
            <div className=" bg-gray-200 items-center content-center flex flex-col">
              <div className=" bg-gray-200 slide-container items-center w-2/3 content-center">
                <Zoom {...zoomOutProperties}>
                  {postDetail.sliderimages.map((slideImage, index) => (
                    <div
                      className="each-fade bg-gray-200"
                      style={{ objectFit: "contain" }}
                      key={index}
                    >
                      <div style={{ objectFit: "contain" }}>
                        <img
                          style={{ width: "100%" }}
                          src={urlMaker(slideImage.url)}
                          alt="ss"
                        />

                        <span className="text-center">
                          {slideImage.caption}
                        </span>
                      </div>
                    </div>
                  ))}
                </Zoom>
              </div>
            </div>
          )}
          <div className=" bg-sky-900 mb-20">
            {postDetail.comments.length > 0 && (
              <div className="sm:pl-60 pl-10 mt-20">
                <div className="flow-root">
                  <ul className="-mb-8 pt-4">
                    {postDetail.comments.map((comment, i) => (
                      <li key={i}>
                        <Comment props={comment} />
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            )}
          </div>
          <NewComment postId={postId} />
        </>
      )}
    </>
  );
};

export default PostDetail;
