import React from 'react'
import { ErrorMessage, useField } from 'formik'

export const TextField = ({ label, ...props }) => {
    const [field, meta] = useField(props)
    return (
        <div className="mb-3 ">
            <label className="block text-sm font-medium text-gray-700" htmlFor={field.name}>{label}</label>
            <input className={`form-control ${meta.touched && meta.error && 'is-invalid'} appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}

                autoComplete="off" {...field} {...props} />
            <ErrorMessage component="div" className=" text-red-500 text-xs p-1" name={field.name} />
        </div>
    )
}