import React from "react";
import Moment from "react-moment";
import { ChatAltIcon } from "@heroicons/react/solid";

const Comment = ({ props }) => {
  return (
    <div className="relative pb-8">
      <div className="relative flex items-start space-x-3">
        <>
          <div className="relative">
            {props.users_permissions_user.avatar ? (
              <img
                className="h-10 w-10 rounded-full bg-gray-400 flex items-center justify-center ring-4 ring-gray-400"
                src={props.users_permissions_user.avatar}
                alt=""
              />
            ) : null}

            <span className="absolute -bottom-0.5 -right-1 bg-white rounded-tl px-0.5 py-px">
              <ChatAltIcon
                className="h-5 w-5 text-gray-600"
                aria-hidden="true"
              />
            </span>
          </div>
          <div className="min-w-0 flex-1">
            <div>
              <div className="text-sm text-stone-400">
                {props.users_permissions_user.username}
              </div>
              <p className="mt-0.5 text-xs text-gray-400">
                <Moment fromNow>{props.createdAt}</Moment>
              </p>
            </div>
            <div className="mt-2 text-sm text-gray-200 max-w-xs">
              <p>{props.comment}</p>
            </div>
          </div>
        </>
      </div>
    </div>
  );
};

export default Comment;
