import React, { useEffect, useState } from "react";

import MasonryLayout from "./MasonryLayout";
import Spinner from "./Spinner";
import { getPosts, getPostsFiltered } from "../utils/data";

const Search = ({ searchTerm }) => {
  const [posts, setPosts] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getAllPosts = async () => {
      setLoading(true);
      const res = await getPosts();
      setPosts(res);
    };

    const getAllPostFiltered = async (st) => {
      setLoading(true);
      const res = await getPostsFiltered(st);
      setPosts(res);
    };

    setLoading(true);
    console.log(searchTerm);
    if (searchTerm === "") {
      getAllPosts();
    } else {
      console.log(searchTerm);
      getAllPostFiltered(searchTerm);
    }

    setLoading(false);
  }, [searchTerm]);

  return (
    <div>
      {loading && <Spinner message="Searching posts" />}
      {posts?.length !== 0 && <MasonryLayout pins={posts} />}
      {posts?.length === 0 && searchTerm !== "" && !loading && (
        <div className="mt-10 text-center text-xl text-white ">
          No Posts Found!
        </div>
      )}
    </div>
  );
};

export default Search;
