import axios from "axios";
import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { getCountOfCat } from "../utils/data";

const isNotActiveStyle =
  "flex items-center px-5 gap-3 text-gray-500 hover:text-black transition-all duration-200 ease-in-out capitalize";
const isActiveStyle =
  "flex items-center px-5 gap-3 font-extrabold border-r-2 border-black  transition-all duration-200 ease-in-out capitalize";

const Category = ({ category, handleCloseSidebar = (f) => f }) => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    const getCount = async (category) => {
      const _count = await getCountOfCat(category);
      setCount(_count);
      //  console.log("Count", _count);
    };

    getCount(category);
  }, [category]);

  return (
    <NavLink
      to={`/category/${category.id}`}
      className={({ isActive }) =>
        isActive ? isActiveStyle : isNotActiveStyle
      }
      onClick={handleCloseSidebar}
      key={category.attributes.id}
    >
      <img
        alt={category.attributes.category}
        src={category.attributes.url}
        className="w-8 h-8 rounded-full shadow-sm"
      />
      <div className="flex flex-row gap-4 content-evenly">
        <span className="text-xs text-slate-300">
          {category.attributes.category}
        </span>{" "}
        <span className=" text-xs text-end rounded-full bg-orange-200 pl-2 pr-2">
          {count}
        </span>
      </div>
    </NavLink>
  );
};

export default Category;
